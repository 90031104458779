import React from 'react';

// === Assets === //
import logoYellow from '@assets/img/logo-yellow.png';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, mixins, respondFrom } from '@styles';
import { css } from '@emotion/react';

const FooterWrapper = styled.div`
  background-color: ${colors.ui.grayDark};
  padding: 35px 0;
`;

const FooterInner = styled.div``;

const FooterLogo = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 130px;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 180px;
    `
  )};
`;

const FooterMenu = styled.div`
  margin-bottom: 25px;
  margin-top: 40px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: 0;
    `
  )};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    ${respondFrom(
      breakpoints.md,
      css`
        flex-flow: row;
      `
    )};

    li {
      margin-bottom: 20px;

      ${respondFrom(
        breakpoints.md,
        css`
          margin-bottom: 0;
        `
      )};
    }

    a {
      color: ${colors.white};
      text-decoration: none;
      font-size: ${dimensions.fontSize.small}px;
      padding: 5px 7.5px;
      ${mixins.transitionDefault};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterCopyrightText = styled.div`
  p {
    text-align: center;
    margin: 0;
    color: ${colors.white};
    opacity: 0.4;
    font-size: ${dimensions.fontSize.small}px;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  const today = new Date();

  return (
    <FooterWrapper>
      <Container>
        <FooterInner>
          <FooterLogo>
            <Link to="/">
              <img src={logoYellow} alt={t('footer_logo_alt')} />
            </Link>
          </FooterLogo>
          <FooterMenu>
            <ul>
              <li>
                <a href="https://www.netto.pl/o-nas/polityka-prywatnosci/" target="_blank">
                  {t('footer_privacy_policy')}
                </a>
              </li>
              <li>
                <a
                  href="https://www.netto.pl/o-nas/polityka-prywatnosci/pliki-cookies/"
                  target="_blank"
                >
                  {t('footer_cookies_policy')}
                </a>
              </li>
              <li>
                <a href={utils.regulationPdfUrl(t('language'))} target="_blank">
                  {t('footer_action_regulations')}
                </a>
              </li>
              <li>
                <a href={`mailto:${t('contact_email')}?subject=${t('contact_email_subject')}`}>
                  {t('footer_contact')}
                </a>
              </li>
            </ul>
          </FooterMenu>
          <FooterCopyrightText>
            <p>{t('footer_copyright', { year: today.getFullYear() })}</p>
          </FooterCopyrightText>
        </FooterInner>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
