import React, { useEffect, useState } from 'react';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import Cookies from 'js-cookie';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, fonts, mixins, respondFrom } from '@styles';

const CookieBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: ${colors.white};
  padding: 15px 0;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  ${mixins.transitionDefault};

  &.is-open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

const CookieBannerInner = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      display: flex;
      align-items: center;
    `
  )}
`;

const CookieBannerText = styled.div`
  flex: 1;
  font-size: ${dimensions.fontSize.tiny}px;
  line-height: 21px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 83%;
    `
  )}

  a {
    text-decoration: none;
    color: ${colors.text.default};

    ${respondFrom(
      breakpoints.lg,
      css`
        &:hover {
          text-decoration: underline;
        }
      `
    )}
  }
`;

const CookieBannerButton = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      margin-left: 30px;
    `
  )}

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${fonts.default};
    font-size: ${dimensions.fontSize.tiny}px;
  }
`;

const CookieBanner = () => {
  const [open, handleOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const cookieName = 'cokieInfo';

  const handleCheckCookies = () => {
    if (Cookies.get(cookieName)) {
      handleOpen(false);
    } else {
      handleOpen(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleCheckCookies();
    }, 500);
  }, []);

  return (
    <CookieBannerWrapper className={open ? 'is-open' : ''}>
      <Container>
        <CookieBannerInner>
          <CookieBannerText>{utils.SafeHtml(`${t('cookie_banner_description')}`)}</CookieBannerText>
          <CookieBannerButton>
            <button
              onClick={() => {
                Cookies.set(cookieName, true, { expires: 365 });
                handleOpen(false);
              }}
            >
              {t('cookie_banner_accept')}
            </button>
          </CookieBannerButton>
        </CookieBannerInner>
      </Container>
    </CookieBannerWrapper>
  );
};

export default CookieBanner;
