import * as React from 'react';
import ReactSafeHtml from 'react-safe-html';

// === Assets === //
import regulationPl from '@assets/pdf/Regulamin_Netto_Sąsiedzka_Pomoc_PL.pdf';
import regulationUa from '@assets/pdf/Regulamin_Netto_Sąsiedzka_Pomoc_UA.pdf';

const stringStyleToObject = (theStyleString: string) => {
  const styleObj: any = {};
  const styles: string[] = [];
  const stylesTemp = theStyleString.split(';');

  stylesTemp.forEach((s: string) => {
    if (s !== '') {
      styles.push(s);
    }
  });

  styles.forEach((item: string) => {
    const s = item.split(': ');

    if (s && s.length > 0) {
      if (s[0] === 'list-style-type') {
        styleObj.listStyleType = s[1];
      }
      // else {
      //   styleObj.s[0] = s[1];
      // }
    }
  });

  return styleObj;
};

export const SafeHtml = (html: string) => {
  const components = ReactSafeHtml.components.makeElements({});
  components.ul = ReactSafeHtml.components.createSimpleElement('ul', {
    value: true,
  });
  components.ol = ReactSafeHtml.components.createSimpleElement('ol', {
    value: true,
    style: (theStyleString) => {
      return ['style', stringStyleToObject(theStyleString)];
    },
    dir: true,
    start: true,
  });
  components.li = ReactSafeHtml.components.createSimpleElement('li', {
    value: true,
  });
  components.br = ReactSafeHtml.components.createSimpleElement('br', {
    value: true,
  });
  components.sup = ReactSafeHtml.components.createSimpleElement('sup', {
    value: true,
  });
  components.a = ReactSafeHtml.components.createSimpleElement('a', {
    value: true,
    href: true,
    target: true,
  });
  components.table = ReactSafeHtml.components.createSimpleElement('table', {
    value: true,
  });
  components.thead = ReactSafeHtml.components.createSimpleElement('thead', {
    value: true,
  });
  components.tr = ReactSafeHtml.components.createSimpleElement('tr', {
    value: true,
  });
  components.th = ReactSafeHtml.components.createSimpleElement('th', {
    value: true,
  });
  components.tbody = ReactSafeHtml.components.createSimpleElement('tbody', {
    value: true,
  });
  components.td = ReactSafeHtml.components.createSimpleElement('td', {
    value: true,
  });

  return <ReactSafeHtml html={html} components={components} />;
};

export const slugify = (s: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return s
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const regulationPdfUrl = (lang: string) => {
  return lang === 'pl' ? regulationPl : regulationUa;
};
