import React from 'react';

// === Assets === //
import ogImage from '@assets/img/sasiedzka_pomoc.png';

// === Components === //
import Helmet from 'react-helmet';

// === Types === //
import { MetaData } from '@type/common';

interface MetaGlobalProps {
  metaData: MetaData;
}

const MetaGlobal = ({ metaData }: MetaGlobalProps) => {
  return (
    <Helmet defer={false} defaultTitle="Rethink Fabry">
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
      <meta name="keywords" content={metaData.keywords} />

      <meta property="og:title" content={metaData.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://sasiedzkapomoc.netto.pl/" />
      <meta property="og:description" content={metaData.description} />
      <meta property="og:image" content={ogImage} />
    </Helmet>
  );
};

export default MetaGlobal;
