import React from 'react';

// === components === //
import Navigation from '@components/layout/Navigation';
import Footer from '@components/layout/Footer';
import MetaGlobal from '@components/layout/MetaGlobal';
import CookieBanner from '@components/layout/CookieBanner';

// === Styles === //
import { Global as StylesGlobal, css } from '@emotion/react';
import { normalize } from '@styles';

// === types === //
import { ActivePage, MetaData } from '@type/common';

interface PageProps {
  children: React.ReactChild;
  metaData: MetaData;
  activePage?: ActivePage;
}

const Page = ({ children, metaData, activePage = null }: PageProps) => {
  return (
    <div>
      <MetaGlobal metaData={metaData} />
      <StylesGlobal styles={() => css(normalize)} />

      <Navigation activePage={activePage} />
      {children}
      <Footer />

      <CookieBanner />
    </div>
  );
};

export default Page;
