export const colors = {
  text: {
    default: '#242424',
    placeholder: '#A6A6A6',
    error: '#CF2C0F',
  },
  ui: {
    main: '#FAD85B',
    second: '#005BBB',
    grayLight: '#F7F7F7',
    grayDark: '#242424',
  },
  white: '#fff',
  black: '#000',
};

export const fonts = {
  default: 'GentonaNettoBook, sans-serif',
  extraBold: 'GentonaNettoExtraBold, sans-serif',
  light: 'GentonaNettoLight, sans-serif',
};

export const breakpoints = {
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1300,
};

export const dimensions = {
  fontSize: {
    large: 28,
    medium: 20,
    regular: 19,
    small: 16,
    tiny: 14,
    logo: 22
  },
  lineHeight: {
    regular: 26,
    heading: 52,
  },
};

export const animation = {
  fast: 200,
  slow: 350,
  subtle: 500,
};
