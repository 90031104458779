import { dimensions, fonts, colors, breakpoints } from './variables';
import { mixins, respondFrom } from './mixins';
import { css } from '@emotion/react';
import { fluidRange } from '@styles';

// === Fonts === //
import gentonaNettoBook from '@assets/fonts/GentonaNetto-Book.otf'
import gentonaNettoExtraBold from '@assets/fonts/GentonaNetto-ExtraBold.otf'
import gentonaNettoLight from '@assets/fonts/GentonaNetto-Light.otf'

export const normalize = css`
  @font-face {
    font-family: GentonaNettoBook;
    font-weight: normal;
    src: url(${gentonaNettoBook}) format('opentype');
  }
  @font-face {
    font-family: 'GentonaNettoExtraBold';
    font-weight: bold;
    src: url(${gentonaNettoExtraBold}) format('opentype');
  }
  @font-face {
    font-family: GentonaNettoLight;
    font-weight: lighter;
    src: url(${gentonaNettoLight}) format('opentype');
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${dimensions.fontSize.regular}px !important;
    line-height: ${dimensions.lineHeight.regular}px !important;
  }

  body {
    width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: ${fonts.default};
    font-weight: 400;
    color: ${colors.text.default};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin: 0;
  }

  body.menu-open {
    overflow-y: hidden;

    ${respondFrom(breakpoints.lg, css`
      overflow-y: auto;
    `)}
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: ${fonts.extraBold};
    font-weight: 800;
    line-height: ${dimensions.lineHeight.heading}px;
    text-rendering: optimizeLegibility;
  }

  h1 {
    ${fluidRange('fontSize', '40px', '72px')}
  }

  h2 {
    ${fluidRange('fontSize', '27px', '48px')}
    ${fluidRange('lineHeight', '32px', '53px')}
    margin-bottom: 30px;
  }
  h3 {
    ${fluidRange('fontSize', '23px', '28px')}
    ${fluidRange('lineHeight', '28px', '34px')}
  }

  p,
  .p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  sup,
  sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
    font-size: ${dimensions.fontSize.small}px
  }
  sub {
    top: 0.4em;
  }

  #gatsby-focus-wrapper {
    overflow-x: hidden;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    max-width: 318px !important;
  }

  .react-datepicker__header  {
    background-color: ${colors.ui.grayDark} !important;
  }

  .react-datepicker__current-month {
    color: ${colors.ui.main} !important;
    font-family: ${fonts.extraBold} !important;
    font-weight: 800 !important;
  }

  .react-datepicker__day-name {
    color: ${colors.ui.main} !important;
    font-family: ${fonts.default} !important;
  }

  .react-datepicker__day {
    font-family: ${fonts.default} !important;
    color: ${colors.text.default} !important;
  }

  .react-datepicker__day--today {
    font-family: ${fonts.extraBold} !important;
    font-weight: 800 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.ui.grayDark} !important;
    color: ${colors.white} !important;
  }

  .react-datepicker__navigation-icon {
    &::before {
      border-color: ${colors.ui.main} !important;
      ${mixins.transitionDefault};
    }
  }

  .react-datepicker__navigation {
    ${respondFrom(breakpoints.lg, css`
      &:hover {
        .react-datepicker__navigation-icon::before {
          border-color: ${colors.white} !important;
        }
      }
    `)}
  }
`;