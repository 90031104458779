import React from 'react';

// === Helpers === //
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, fonts, respondTo } from '@styles';
import { css } from '@emotion/react';

const ParagraphWrapper = styled.div`
  strong {
    font-family: ${fonts.extraBold};
    font-weight: 800;
  }

  &.m16 {
    ${respondTo(
      breakpoints.lg,
      css`
        font-size: 16px;
        line-height: 23px;
      `
    )};
  }

  &.m18 {
    ${respondTo(
      breakpoints.lg,
      css`
        font-size: 18px;
        line-height: 24px;
      `
    )};
  }
`;

interface ParagraphProps {
  htmlAsString: string;
  className?: string;
}

const Paragraph = ({ htmlAsString, className = '' }: ParagraphProps) => {
  return <ParagraphWrapper className={className}>{utils.SafeHtml(htmlAsString)}</ParagraphWrapper>;
};

export default Paragraph;
