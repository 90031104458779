import { dimensions, animation, breakpoints, colors } from './variables';
import { css, SerializedStyles } from '@emotion/react';
import { fluidRange as polishedFluidRange } from 'polished';

/* --- animation helpers --- */
const transitionDefaultProperties = `transform, opacity, visibility, color, background-color, stroke, background-image, border-color`;

export const transitionDefault = css`
  transition: ${animation.fast}ms ease-out;
  transition-property: ${transitionDefaultProperties};
  will-change: ${transitionDefaultProperties};
`;

/* --- responsive helpers --- */
export const fluidRange = (
  prop: string,
  fromSize: string | number,
  toSize: string | number,

  minScreen?: number,
  maxScreen?: number
) => css`
  ${polishedFluidRange(
    { prop, fromSize, toSize },
    `${minScreen || breakpoints.sm}px`,
    `${maxScreen || breakpoints.xl}px`
  )}
`;

export const respondFrom = (minW: number, content: SerializedStyles) => css`
  @media (min-width: ${minW}px) {
    ${content}
  }
`;
export const respondTo = (maxW: number, content: SerializedStyles) => css`
  @media (max-width: ${maxW - 1}px) {
    ${content}
  }
`;

export const mixins = {
  transitionDefault,

  fluidRange,
  respondFrom,
  respondTo,
}