import React from 'react';
import { css } from '@emotion/react';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, respondTo, widths } from '@styles';

const ContainerWrapper = styled.div`
  max-width: ${widths.xl}px;
  margin: auto;
  padding: 0 25px;
  ${respondTo(
    breakpoints.md,
    css`
      padding: 0 20px;
    `
  )}
`;

interface ContainerProps {
  children: React.ReactChild;
}

const Container = ({ children, ...props }: ContainerProps) => {
  return <ContainerWrapper {...props}>{children}</ContainerWrapper>;
};

export default Container;
